// margin all sides
.m-a-none {
  margin: 0 !important;
}

.m-a-xxs {
  margin: 0.25rem !important;
}

.m-a-xs {
  margin: 0.5rem !important;
}

.m-a-s {
  margin: 0.75rem !important;
}

.m-a-sm {
  margin: 1rem !important;
}

.m-a-m {
  margin: 1.25rem !important;
}

.m-a-ml {
  margin: 1.5rem !important;
}

.m-a-l {
  margin: 1.75rem !important;
}

.m-a-xl {
  margin: 2rem !important;
}

.m-a-xxl {
  margin: 3rem !important;
}

// margin top
.m-t-none {
  margin-top: 0 !important;
}

.m-t-xxs {
  margin-top: 0.25rem !important;
}

.m-t-xs {
  margin-top: 0.5rem !important;
}

.m-t-s {
  margin-top: 0.75rem !important;
}

.m-t-sm {
  margin-top: 1rem !important;
}

.m-t-m {
  margin-top: 1.25rem !important;
}

.m-t-ml {
  margin-top: 1.5rem !important;
}

.m-t-l {
  margin-top: 1.75rem !important;
}

.m-t-xl {
  margin-top: 2rem !important;
}

.m-t-xxl {
  margin-top: 3rem !important;
}

// margin bottom
.m-b-none {
  margin-bottom: 0rem !important;
}

.m-b-xxs {
  margin-bottom: 0.25rem !important;
}

.m-b-xs {
  margin-bottom: 0.5rem !important;
}

.m-b-s {
  margin-bottom: 0.75rem !important;
}

.m-b-sm {
  margin-bottom: 1rem !important;
}

.m-b-m {
  margin-bottom: 1.25rem !important;
}

.m-b-ml {
  margin-bottom: 1.5rem !important;
}

.m-b-l {
  margin-bottom: 1.75rem !important;
}

.m-b-xl {
  margin-bottom: 2rem !important;
}

.m-b-xxl {
  margin-bottom: 3rem !important;
}

// margin left
.m-l-auto {
  margin-left: auto !important;
}

.m-l-none {
  margin-left: 0 !important;
}

.m-l-xxs {
  margin-left: 0.25rem !important;
}

.m-l-xs {
  margin-left: 0.5rem !important;
}

.m-l-s {
  margin-left: 0.75rem !important;
}

.m-l-sm {
  margin-left: 1rem !important;
}

.m-l-m {
  margin-left: 1.25rem !important;
}

.m-l-ml {
  margin-left: 1.5rem !important;
}

.m-l-l {
  margin-left: 1.75rem !important;
}

.m-l-xl {
  margin-left: 2rem !important;
}

.m-l-xxl {
  margin-left: 3rem !important;
}

// margin right
.m-r-auto {
  margin-right: auto !important;
}

.m-r-none {
  margin-right: 0rem !important;
}

.m-r-xxs {
  margin-right: 0.25rem !important;
}

.m-r-xs {
  margin-right: 0.5rem !important;
}

.m-r-s {
  margin-right: 0.75rem !important;
}

.m-r-sm {
  margin-right: 1rem !important;
}

.m-r-m {
  margin-right: 1.25rem !important;
}

.m-r-ml {
  margin-right: 1.5rem !important;
}

.m-r-l {
  margin-right: 1.75rem !important;
}

.m-r-xl {
  margin-right: 2rem !important;
}

.m-r-xxl {
  margin-right: 3rem !important;
}
