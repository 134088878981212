// padding all sides
.p-a-none {
  padding: 0 !important;
}

.p-a-xxs {
  padding: 0.25rem !important;
}

.p-a-xs {
  padding: 0.5rem !important;
}

.p-a-s {
  padding: 0.75rem !important;
}

.p-a-sm {
  padding: 1rem !important;
}

.p-a-m {
  padding: 1.25rem !important;
}

.p-a-ml {
  padding: 1.5rem !important;
}

.p-a-l {
  padding: 1.75rem !important;
}

.p-a-xl {
  padding: 2rem !important;
}

.p-a-xxl {
  padding: 3rem !important;
}

// padding top
.p-t-none {
  padding-top: 0 !important;
}

.p-t-xxs {
  padding-top: 0.25rem !important;
}

.p-t-xs {
  padding-top: 0.5rem !important;
}

.p-t-s {
  padding-top: 0.75rem !important;
}

.p-t-sm {
  padding-top: 1rem !important;
}

.p-t-m {
  padding-top: 1.25rem !important;
}

.p-t-ml {
  padding-top: 1.5rem !important;
}

.p-t-l {
  padding-top: 1.75rem !important;
}

.p-t-xl {
  padding-top: 2rem !important;
}

.p-t-xxl {
  padding-top: 3rem !important;
}

// padding bottom
.p-b-none {
  padding-bottom: 0 !important;
}

.p-b-xxs {
  padding-bottom: 0.25rem !important;
}

.p-b-xs {
  padding-bottom: 0.5rem !important;
}

.p-b-s {
  padding-bottom: 0.75rem !important;
}

.p-b-sm {
  padding-bottom: 1rem !important;
}

.p-b-m {
  padding-bottom: 1.25rem !important;
}

.p-b-ml {
  padding-bottom: 1.5rem !important;
}

.p-b-l {
  padding-bottom: 1.75rem !important;
}

.p-b-xl {
  padding-bottom: 2rem !important;
}

.p-b-xxl {
  padding-bottom: 3rem !important;
}

// padding left
.p-l-none {
  padding-left: 0 !important;
}

.p-l-xxs {
  padding-left: 0.25rem !important;
}

.p-l-xs {
  padding-left: 0.5rem !important;
}

.p-l-s {
  padding-left: 0.75rem !important;
}

.p-l-sm {
  padding-left: 1rem !important;
}

.p-l-m {
  padding-left: 1.25rem !important;
}

.p-l-ml {
  padding-left: 1.5rem !important;
}

.p-l-l {
  padding-left: 1.75rem !important;
}

.p-l-xl {
  padding-left: 2rem !important;
}

.p-l-xxl {
  padding-left: 3rem !important;
}

// padding right
.p-r-none {
  padding-right: 0 !important;
}

.p-r-xxs {
  padding-right: 0.25rem !important;
}

.p-r-xs {
  padding-right: 0.5rem !important;
}

.p-r-s {
  padding-right: 0.75rem !important;
}

.p-r-sm {
  padding-right: 1rem !important;
}

.p-r-m {
  padding-right: 1.25rem !important;
}

.p-r-ml {
  padding-right: 1.5rem !important;
}

.p-r-l {
  padding-right: 1.75rem !important;
}

.p-r-xl {
  padding-right: 2rem !important;
}

.p-r-xxl {
  padding-right: 3rem !important;
}
